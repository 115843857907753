@import 'variables';

.runeGrid {
  display: flex; 
  
  .runeWrapper {
    button {
      cursor: auto;
    }
  }
}