@import 'variables';

.actionBar {
  width: $actionWidth;
  font-family: 'norse';
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  right: 0;
  bottom: $runeCastHeight + $poolHeight + 40;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // border-top-left-radius: $toolRadius;
  // border-bottom-left-radius: $toolRadius;
  // box-shadow: 0px 0px 14px #00000036;
  // background: linear-gradient(180deg, $actionBarColor 0%, darken($actionBarColor, 2%) 100%);
  z-index: 6;

  &.left {
    left: 0;
    right: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}

.energyDotsWrapper {
  position: absolute;
  right: 0;
  min-width: $actionWidth;
  padding: 2px 20px 10px 20px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;

  .energy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100px;
    direction: rtl;

    .energyDot {
      width: 24px;
      height: 24px;
    }
  }
}

.energyReserve {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actionsCount {
  display: flex;
  gap: 20px;
  flex-direction: column;

  img {
    cursor: pointer;
    z-index: 1;
    position: relative;
    width: 80px;

    &.disabled {
      cursor: auto;
      opacity: 0.3;
    }
  }
}