@import 'variables';


.castExplanation {
  font-family: 'norse';
  font-size: $smallFont;
  position: absolute;
  bottom: 0;
  display: flex;   
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .explanation {
    display: flex;   
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      color: black;
      margin-right: 5px;
      padding: 4px 8px;
      border-radius: 4px;
      // text-shadow: 0px 0px 1px white;
      font-size: 16px;
    }

    &.wrong {
      span {
        background: $wrong linear-gradient(30deg, lighten($wrong, 20%) 0%, $wrong 50%, lighten($wrong, 20%) 100%);
      }
    }

    &.present {
      span {
        background: white linear-gradient(30deg, lighten($present, 40%) 0%, $present 50%, lighten($present, 40%) 100%);
      }
    }
    
    &.found {
      span {
        background-color: $found;
      }
    }
  }

}