@import 'variables';

.globalEffectsWrapper {
  position: fixed;
  width: $actionWidth;
  height: 100%;
  padding-bottom: 300px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 4;
}

.globalEffect {
  position: relative;
  display: flex;
  width: $actionWidth;
  height: 70px;
  font-size: $smallFont;
  align-items: center;
  font-family: 'norse';
  border-radius: 0px 6px 6px 0px;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ffffff17;
  border-left: none;
  box-shadow: 0px 0px 8px 0px #00000047;
  animation: bounce-in-right 1s ease;
  z-index: 2;
  
  background-color: #7d00007d;

  &.increaseVictoryEnergy {
    background-color: rgba($color: #004e1b, $alpha: 0.3);
  }

  .description {
    position: absolute;
    width: 220px;
    left: 100%;
    opacity: 0;
    padding: 10px;
    border-radius: 0px 6px 6px 0px;
    padding-left: 20px;
    background-color: rgba($color: #0000002d, $alpha: 0.3);
    transition: all 400ms ease-in-out;
    z-index: 1;
    pointer-events: none;
  }

  img {
    width: 32px;
    margin-right: 12px;
  }

  &:hover {
    .description {
      opacity: 1;
      transition: all 400ms ease-in-out;
    }
  }
}