@import 'variables';

.actionButton {
  font-size: $largeFont;
  font-family: 'norse';
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  text-align: left;
  padding: 10px 10px 10px 120px;
  gap: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  width: 100%;
  max-width: 400px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  will-change: transform; // 60FPS! :o

  &.large {
    font-size: $titleFont
  }

  &.small {
    padding: 10px 10px 10px 100px;
  }

  &.special {
    color: $found;
    text-shadow: 0px 0px 3px black;
    margin-bottom: 30px !important;
  }

  span {
    display: block;
    font-size: $smallFont;
  }

  img {
    width: 100px;
    position: absolute;
    left: 10px;
  }

  &.small img {
    width: 80px;
  }

  .buttonPicture {
    position: absolute;
    left: 0;
    top: 5px;

    img {
      width: 64px;
    }
  }

  .qty {
    position: relative;
    bottom: -30px;
    left: 50px;
  }

  .subtitle {
    font-size: $smallFont;
    opacity: 0.5;
  }

  &.disabled {
    opacity: .3;
    cursor: auto;
  }

  &.inactive {
    max-width: 500px;
    padding-left: 90px;
    background: none;
    margin-bottom: 0px;
  }

  &.notSelected {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  &:not(.inactive, .disabled):hover {
    transition: all 200ms ease-in-out;
    transform: scale(1.2);
    background-color: rgba($color: #000000, $alpha: 0.7);
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.6));
    box-shadow: inset 0px 0px 12px 3px #00000070;
  }
}

.secondaryButton {
  font-size: $smallFont;
  font-family: 'norse';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  padding: 10px;
  gap: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  width: 100%;
  max-width: 400px;
  background-color: transparent;
  will-change: transform; // 60FPS! :o

  &.disabled {
    opacity: .3;
    cursor: auto;
  }

  &:not(.inactive, .disabled):hover {
    transition: all 200ms ease-in-out;
    transform: scale(1.2);
  }
}

#playerNameInput {
  font-size: $heroFont;
  font-family: 'norse';
  text-align: center;
  border: none;
  color: white;
  // text-shadow: 0px 3px 3px black;
  border-radius: 8px;
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 20px 0px;
  padding: 20px;
  background-color: #0000004f;
}