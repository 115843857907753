@import 'variables';

@keyframes glowFound {
  0% { filter: drop-shadow(0px 0px 0px $found);  }
  50% { filter: drop-shadow(0px 0px 15px $found); }
  100% { filter: drop-shadow(0px 0px 0px $found); } 
}

@keyframes glowPresent {
  0% { filter: drop-shadow(0px 0px 0px $present);  }
  50% { filter: drop-shadow(0px 0px 15px $present); }
  100% { filter: drop-shadow(0px 0px 0px $present); } 
}

// Rune played

/* starting ENTER animation */
.runePlayed-enter {
  opacity: 0;
  transform: scale(2);
}

/* ending ENTER animation */
.runePlayed-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 400ms ease-in-out;
}

/* starting EXIT animation */
.runePlayed-exit {
  opacity: 1;
  transform: scale(1);
}

/* ending EXIT animation */
.runePlayed-exit-active {
  opacity: 0;
  transform: scale(.4);
  transition: all 400ms ease-in-out;
}


// For cast only
.runePlayed_cast-enter {
  // opacity: 0;
  transform: scale(2);
}

.runePlayed_cast-enter-active {
  // opacity: 1;
  transform: scale(1);
  transition: all 400ms ease-in-out;
}

.runePlayed_cast-exit {
  opacity: 1;
}

/* ending EXIT animation */
.runePlayed_cast-exit-active {
  opacity: 0;
  transition: all 400ms ease-in-out;
}

// Energy lost
.energyVariation-enter {
  opacity: 0;
  transform: translate(0, 200px) scale(1.4);
}

.energyVariation-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 800ms ease-in-out;
}

.energyVariation-exit {
  opacity: 1;
  transform: scale(1.01);
}

.energyVariation-exit-active {
  opacity: 0;
  transform: translate(0, 200px) scale(.4);
  transition: all 800ms ease-in-out;
}

// Token activation
.tokenActivation-enter {
  opacity: 0;
  transform: scale(4);
}

.tokenActivation-enter-active {
  opacity: 1;
  transition: all 800ms ease-in-out;
}

.tokenActivation-exit {
  opacity: 1;
  transform: scale(1.01);
}

.tokenActivation-exit-active {
  opacity: 0;
  transform: scale(4);
  transition: all 800ms ease-in-out;
}

// Energy won
.energyVariationPrize-enter {
  opacity: 0;
  transform: translate(0, 200px) scale(1.4);
}

.energyVariationPrize-enter-active {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  transition: all 800ms ease-in-out;
}

.energyVariationPrize-exit {
  opacity: 1;
  transform: scale(1.01);
}

.energyVariationPrize-exit-active {
  opacity: 0;
  transform: translate(0, 200px) scale(.4);
  transition: all 800ms ease-in-out;
}

// Grid lines 

.gridline-enter {
  opacity: 0;
  transform: scale(1.2);
}

/* ending ENTER animation */
.gridline-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 600ms ease-in-out;
}

/* starting EXIT animation */
.gridline-exit {
  opacity: 1;
  transform: scale(1);
}

/* ending EXIT animation */
.gridline-exit-active {
  z-index: 200;
  opacity: 0;
  transition: all 600ms ease-in-out;
}

/* Character entrances / exits */
.leftEntrance-enter, .leftEntrance-appear {
  opacity: 0;
  transform: translateX(-200px);
}

.leftEntrance-enter-active, .leftEntrance-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 600ms ease-in-out !important;
}

.leftEntrance-exit {
  opacity: 1;
  transform: translateX(0px);
}

.leftEntrance-exit-active {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 600ms ease-in-out !important;
}

// 

.rightEntrance-enter, .rightEntrance-appear {
  opacity: 0;
  transform: translateX(200px);
}

.rightEntrance-enter-active, .rightEntrance-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 600ms ease-in-out !important;
}

.rightEntrance-exit {
  opacity: 1;
  transform: translateX(0px);
}

.rightEntrance-exit-active {
  opacity: 0;
  transform: translateX(200px);
  transition: all 600ms ease-in-out !important;
}

/// slow versions
.leftEntranceSlow-enter, .leftEntranceSlow-appear {
  opacity: 0;
  transform: translateX(-200px);
}

.leftEntranceSlow-enter-active, .leftEntranceSlow-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 1200ms ease-in-out !important;
}

.leftEntranceSlow-exit {
  opacity: 1;
  transform: translateX(0px);
}

.leftEntranceSlow-exit-active {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 1200ms ease-in-out !important;
}

// 

.rightEntranceSlow-enter, .rightEntranceSlow-appear {
  opacity: 0;
  transform: translateX(200px);
}

.rightEntranceSlow-enter-active, .rightEntranceSlow-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 1200ms ease-in-out !important;
}

.rightEntranceSlow-exit {
  opacity: 1;
  transform: translateX(0px);
}

.rightEntranceSlow-exit-active {
  opacity: 0;
  transform: translateX(200px);
  transition: all 1200ms ease-in-out !important;
}

// Hostility pulses
@keyframes hostileTyr {
  0% {
    background-color: saturate(lighten($tyr, 15%), 20%);
  }
  50% {
    background-color: saturate(lighten($tyr, 45%), 60%);
  }
  100% {
    background-color: saturate(lighten($tyr, 15%), 20%);
  }
}

@keyframes hostileHeimdall {
  0% {
    background-color: saturate(lighten($heimdall, 15%), 20%);
  }
  50% {
    background-color: saturate(lighten($heimdall, 45%), 60%);
  }
  100% {
    background-color: saturate(lighten($heimdall, 15%), 20%);
  }
}

@keyframes hostileFreyr {
  0% {
    background-color: saturate(lighten($freyr, 15%), 10%);
  }
  50% {
    background-color: saturate(lighten($freyr, 35%), 20%);
  }
  100% {
    background-color: saturate(lighten($freyr, 15%), 10%);
  }
}

@keyframes wildcard {
  0% { color: darken($found, 100%); }
  50% { color: $found; 
    filter: drop-shadow(0px 0px 5px desaturate($found, 40%)); }
  100% { color: darken($found, 100%); }
}

// Combination pulses
@keyframes glow_freyr_pulse {
  0% {  }
  25% { filter: drop-shadow(0px 0px 6px saturate(lighten($freyr, 45%), 70%)) }
  50% { filter: drop-shadow(0px 0px 10px saturate(lighten($freyr, 45%), 70%)) brightness(1.5); }
  75% { filter: drop-shadow(0px 0px 6px saturate(lighten($freyr, 45%), 70%)) } 
  100% {  } 
}
@keyframes glow_tyr_pulse {
  0% { }
  25% { filter: drop-shadow(0px 0px 6px saturate(lighten($tyr, 45%), 70%)) }
  50% { filter: drop-shadow(0px 0px 10px saturate(lighten($tyr, 45%), 70%)) brightness(1.5); }
  75% { filter: drop-shadow(0px 0px 6px saturate(lighten($tyr, 45%), 70%)) } 
  100% { } 
}
@keyframes glow_heimdall_pulse {
  0% {  }
  25% { filter: drop-shadow(0px 0px 6px saturate(lighten($heimdall, 45%), 70%)) }
  50% { filter: drop-shadow(0px 0px 10px saturate(lighten($heimdall, 45%), 70%)) brightness(1.5); }
  75% { filter: drop-shadow(0px 0px 6px saturate(lighten($heimdall, 45%), 70%)) } 
  100% {  } 
}

/** Fade between views transition */
.zoomView_1000-enter, .zoomView_1000-appear {
  opacity: 0;
  transform: scale(2) translateY(-100px);
  z-index: 30;
}

.zoomView_1000-enter-active, .zoomView_1000-appear-active, .zoomView_1000-enter-done, .zoomView_1000-appear-done {
  opacity: 1;
  transform: scale(1);
  transition: all 1500ms ease-in-out;
  z-index: 30;
}

.zoomView_1000-exit  {
  opacity: 1;
  z-index: 30;
}

.zoomView_1000-exit-active {
  opacity: 0;
  transform: scale(0.1) rotate(270deg);
  transition: all 500ms ease-in-out;
}

// delays
.effect_delay_1 {
  animation-delay: 0.5s;
}

.effect_delay_2 {
  animation-delay: 1s;
}

.effect_delay_3 {
  animation-delay: 1.5s;
}

.effect_delay_4 {
  animation-delay: 2s;
}

.effect_delay_5 {
  animation-delay: 2.5s;
}


///////////

// New item glow
.animation-new {
  animation: new (.3s);
}

.animation-legacy {
  animation: new (1s);
  border: 2px solid $color_legacy !important;
  background: darken(rgba($color_legacy, .2),10) !important;
}

// Deleted item
.animation-deleted {
  animation: delete (2s);
}

// Log effects
.reflectEffect {
  animation: glowReflect (5s) infinite;
}

.boostEffect {
  animation: glowBoost (5s) infinite;
}

// .unique.glow_fire { animation: glow_fire (3s) infinite; }
// .unique.glow_earth { animation: glow_earth (3s) infinite; }
// .unique.glow_water { animation: glow_water (3s) infinite; }
// .unique.glow_light { animation: glow_light (3s) infinite; }
// .unique.glow_darkness { animation: glow_darkness (3s) infinite; }

/* Fumble background anim */
@keyframes effect {
  0% {
    opacity: 0;
    background-position: center -200px;
  }
  50% {
    background-position: center center;
    opacity: 1;
  }
  100% { 
    opacity: 0;
    background-position: center 300px;
  }
}

@keyframes effect-opponent {
  0% {
    opacity: 0;
    background-position: center -201px;
  }
  50% {
    background-position: center center;
    opacity: 1;
  }
  100% { 
    opacity: 0;
    background-position: center 301px;
  }
}

/* Attack animation : rubber bounce */
@keyframes monsterAttack {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: translateY(-50%) scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: translateY(-80%) scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: translateY(-30%) scale3d(0.75, 1.25, 1) scale(1.20);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: translateY(-20%) scale3d(1.15, 0.85, 1) scale(1.20);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: translateY(-30%) scale3d(0.95, 1.05, 1) scale(1.40);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: translateY(-40%) scale3d(1.05, 0.95, 1) scale(1.20);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: translateY(-50%) scale3d(1, 1, 1);
  }
}

@keyframes bounce-in-right {
  0% {
  }
  60% {
    opacity: 1;
    transform: scale(1.3) translateX(20px);
  }
  80% { transform: scale(0.9); }
  100% { transform: translateX(0); }
}

@keyframes spin {
    from {
        transform:rotate(0deg) translateZ(0);
    }
    to {
        transform:rotate(360deg) translateZ(0);
    }
}

@keyframes rumble {
    0% {transform: rotate(0deg);}
    20% {transform: translate(4px, 0px) rotate(-2deg);}
    40% {transform: translate(2px, -6px) rotate(2deg);}
    60% {transform: translate(-2px, -4px) rotate(0deg);}
    80% {transform: translate(6px, 2px) rotate(2deg);} 
    90% {transform: translate(4px, -2px) rotate(-2deg);} 
    100% {transform: translate(0px, 0px) rotate(0deg);} 
}

.animation-rumble {
  animation: rumble 0.40s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes moveSlideshow {  
  100% { 
    transform: translateX(-3050px);  
  }
}
@keyframes moveSlideshowLong {  
  100% { 
    transform: translateX(-5750px);  
  }
}

@keyframes flash {
  from {  } 
  92% { filter: brightness(1) }
  93% { filter: brightness(2) }
  94% { filter: brightness(1.5) }
  95% { filter: brightness(3) } 
  96% { filter: brightness(1) }
  to {  }
}

@keyframes glow {
    0% { filter: drop-shadow(0px 0px 30px #fc266b); }
    20% { filter: drop-shadow(0px 0px 30px #fc266b) drop-shadow(0px 0px 40px #fc266b); }
    45% { filter: drop-shadow(0px 0px 10px #ffc4a2) drop-shadow(0px 0px 15px #ffc4a2); }
    50% { filter: drop-shadow(0px 0px 40px #ffc4a2) drop-shadow(0px 0px 40px #ffc4a2); }
    55% { filter: drop-shadow(0px 0px 10px #ffc4a2) drop-shadow(0px 0px 15px #ffc4a2); }
    80% { filter: drop-shadow(0px 0px 30px #fc266b) drop-shadow(0px 0px 40px #fc266b); }
    100% {filter: drop-shadow(0px 0px 30px #fc266b); } 
}

@keyframes glow_fire {
    0% { filter: drop-shadow(0px 0px 3px $element_fire); }
    50% { filter: drop-shadow(0px 0px 6px saturate(lighten($element_fire, 10), 30)) brightness(1.1); }
    100% { filter: drop-shadow(0px 0px 3px $element_fire); } 
}

@keyframes glow_earth {
    0% { filter: drop-shadow(0px 0px 3px $element_earth); }
    50% { filter: drop-shadow(0px 0px 6px saturate(lighten($element_earth, 10), 30)) brightness(1.1); }
    100% { filter: drop-shadow(0px 0px 3px $element_earth); } 
}

@keyframes glow_water {
    0% { filter: drop-shadow(0px 0px 3px $element_water); }
    50% { filter: drop-shadow(0px 0px 6px saturate(lighten($element_water, 10), 30)) brightness(1.1); }
    100% { filter: drop-shadow(0px 0px 3px $element_water); } 
}

@keyframes glow_light {
    0% { filter: drop-shadow(0px 0px 3px $element_light); }
    50% { filter: drop-shadow(0px 0px 6px saturate(lighten($element_light, 10), 30)) brightness(0.9); }
    100% { filter: drop-shadow(0px 0px 3px $element_light); } 
}

@keyframes glow_darkness {
    0% { filter: drop-shadow(0px 0px 3px $element_darkness); }
    50% { filter: drop-shadow(0px 0px 6px saturate(lighten($element_darkness, 10), 30)) brightness(1.1); }
    100% { filter: drop-shadow(0px 0px 3px $element_darkness); } 
}

@keyframes glowReflect {
    0% { filter: drop-shadow(0px 0px 10px $color_MAG);  }
    16% { filter: drop-shadow(0px 0px 20px $color_MAG); }
    32% { filter: drop-shadow(0px 0px 10px $color_MAG); }
    48% { filter: drop-shadow(0px 0px 60px $color_MAG); }
    64% { filter: drop-shadow(0px 0px 10px $color_MAG); }
    82% { filter: drop-shadow(0px 0px 20px $color_MAG); }
    100% { filter: drop-shadow(0px 0px 10px $color_MAG); } 
}

@keyframes new {
    10% { 
      filter: drop-shadow(0px 0px 1px #ffc4a2) drop-shadow(0px 0px 1px #ffc4a2);
      transform: scale(1.2);
    }
    50% { 
      filter: drop-shadow(0px 0px 20px #ffc4a2) drop-shadow(0px 0px 10px #ffc4a2);
     }
    100% {} 
}

@keyframes delete {
    0% { 
      filter: drop-shadow(0px 0px 1px red) drop-shadow(0px 0px 1px red);
      transform: scale(1.4);
    }
    50% { 
      filter: drop-shadow(0px 0px 20px red) drop-shadow(0px 0px 10px red);
      background: red;
     }
    100% {} 
}

@keyframes danger {
    0% { opacity: 0; }
    60% { opacity: .3;}
    100% { opacity: 0;} 
}

@mixin animation-danger($danger) {
  animation: danger (10s-$danger) infinite;
}

/** TRANSITIONS **/
/* starting ENTER animation */

/* starting ENTER animation */
.runeCostVariation-enter {
  opacity: 0;
}

/* ending ENTER animation */
.runeCostVariation-enter-active {
  opacity: 1;
  transition: all 800ms ease-in-out;
}

/* starting EXIT animation */
.runeCostVariation-exit {
  opacity: 1;
}

/* ending EXIT animation */
.runeCostVariation-exit-active {
  opacity: 0;
  transition: all 800ms ease-in-out;
}

/** Fade between views transition */
.fadeView_1000-enter, .fadeView_1000-appear {
  opacity: 0;
}

.fadeView_1000-enter-active, .fadeView_1000-appear-active, .fadeView_1000-appear-done {
  opacity: 1;
  transition: all 1000ms ease-in-out;
}

.fadeView_1000-exit  {
  opacity: 1;
}

.fadeView_1000-exit-active {
  opacity: 0;
  // transform: scale(2);
  transition: all 1000ms ease-in-out;
}

/** Fade between views transition */
.slideView_1000-enter, .slideView_1000-appear {
  opacity: 0;
  transform: translateY(200px);
}

.slideView_1000-enter-active, .slideView_1000-appear-active, .slideView_1000-enter-done, .slideView_1000-appear-done {
  opacity: 1;
  transition: all 1000ms ease-in-out;
}

.slideView_1000-exit  {
  opacity: 1;
}

.slideView_1000-exit-active {
  opacity: 0;
  transform: translateY(200px);
  transition: all 1000ms ease-in-out;
}

/** Small slide */
.smallSlide-enter, .smallSlide-appear {
  opacity: 0;
  transform:rotateY(90deg);
}

.smallSlide-enter-active, .smallSlide-appear-active, .smallSlide-enter-done, .smallSlide-appear-done {
  opacity: 1;
  transition: all 1000ms ease-in-out;
}

.smallSlide-exit  {
  opacity: 1;
}

.smallSlide-exit-active {
  opacity: 0;
  transform: rotateY(-90deg);
  transition: all 1000ms ease-in-out;
}


/** Fade between views transition */
.fade-enter, .fade-appear {
  opacity: 0;
}

/* ending ENTER animation */
.fade-enter-active, .fade-appear-active, .fade-appear-done {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

/* starting EXIT animation */
.fade-exit, .fade-leave {
  opacity: 1;
}

/* ending EXIT animation */
.fade-exit-active, .fade-leave-active {
  opacity: 0;
  transition: all 300ms ease-in-out;
}