@import 'variables';

.energyDot {
  position: relative;
  margin: 3px;
  font-size: 1px;

  img {
    width: 32px;
    position: relative;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }

  &.prize {
    // background-color: $found;
  }
}

.victoryEnergy {
  position: absolute;
  bottom: 0;
  display: flex;    
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;

  .energyDot {
    width: 24px;
    margin: 0;
  }
}