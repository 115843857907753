@import 'variables';

.runeLineExternalWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

// In rune cast area we need an additonal margin for centering
.runeCast .runeLineExternalWrapper {
  margin-left: $callButtonWidth;
}

.runeLineWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 120px;
  padding: 10px 0px;
  transition: all 400ms ease-in-out;
  
  &.hidden {
    opacity: 0;
    transition: all 400ms ease-in-out;
  }
}

.runePool {
  // position: absolute;
  // width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // &.tyr .buttonEffects .energyDot { border: 4px solid $tyr; }
  // &.heimdall .buttonEffects .energyDot { border: 4px solid $heimdall; }
  // &.freyr .buttonEffects .energyDot { border: 4px solid $freyr; }
}

.buttonEffects .energyDot:not(.energyBonus) { 
  box-shadow: none;
  position: relative;
  top: -5px;
 }