@import 'variables';

@keyframes shake-animation {
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(3px,0) }
  3.57143% { transform:translate(0,2px) }
  5.35714% { transform:translate(3px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(3px,-2px) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

.rune, .runeSocket, .ghostRune, .buttonWrapper {
  width: 60px;
  height: 80px;
  border-radius: 10px;
}

.socketWrapper {
  position: relative;
}

.runeWrapper, .socketWrapper {
  width: 60px;
  height: 80px;
  margin: 10px;
}

.ghostRune { position: absolute; }

.runeWrapper {
  position: relative;
}

.buttonWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.rune {
  position: absolute;
  font-family: "futhark";
  font-size: 50px;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  // box-shadow: inset rgba(0, 0, 0, 0.2) 0px 5px 15px 0px, rgba(0, 0, 0, 0.45) 0px 5px 15px 0px, #463e41 0px 0px 2px 0px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
  0px 7px 13px -3px rgba(0,0,0,0.3),
  0px -3px 0px 0px rgba(0,0,0,0.2) inset;
  z-index: 2;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);

  &.dummy {
    cursor: default;
  }

  .buttonEffects {
    position: absolute;
    width: 24px;
    height: 12px;
    left: 15px;
    top: -1px;
    display: flex;
    justify-content: center;
    // transform: rotate(-45deg);
    background-color: #615958;
    box-shadow: inset 0px -2px 4px 1px rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 8px 8px;

    img { 
      width: 16px;
      margin-top: -5px;
    }

    &.bonus {
      background-color: $found;
      img { 
        margin-top: -11px;
      }
    }
  }

  &.present .buttonEffects {
    background-color: darken($present, 10%);
  }

  &.found .buttonEffects {
    background-color: darken($found, 40%);
  }

  .shimmer {
    display: none;
    opacity: 0.2;
    position: absolute;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .runeSmoke {
    background-image: url('../pictures/texture_smoke.gif');
    background-position: center center;
    mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 30%, transparent 49%);
    display: block;
    height: 134%;
    width: 150%;
    position: absolute;
    left: -14px;
    mix-blend-mode: darken;
    top: -14px;
  }

  .runeSky {
    background-image: url('../pictures/texture_radial.gif');
    background-position: center center;
    mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 30%, transparent 49%);
    display: block;
    height: 124%;
    width: 126%;
    position: absolute;
    left: -7px;
    mix-blend-mode: hard-light;
    top: -9px;
    opacity: 0.3;
  }

  &.wrong {
    // opacity: 0.5;
    background: $wrong linear-gradient(30deg, lighten($wrong, 20%) 0%, $wrong 50%, lighten($wrong, 20%) 100%);
  }

  &.present {
    color: black !important;
    background: white linear-gradient(30deg, lighten($present, 40%) 0%, $present 50%, lighten($present, 40%) 100%);

    .shimmer {
      display: block;
      background: linear-gradient(30deg, transparent 40%, lighten($present, 40%) 50%, transparent 60%);
      background-size: 500%;
      background-position-x: 100%;
      animation: shimmer 10s infinite linear, delay-animation 2000ms linear infinite;
    }
  }

  &.found {
    color: black !important;
    background: $found linear-gradient(30deg, lighten($found, 10%) 0%, darken($found, 20%) 50%, lighten($found, 10%) 100%);

    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
      0px 7px 13px -3px rgba(0,0,0,0.3),
      0px -3px 0px 0px rgba(0,0,0,0.2) inset,
      inset rgba(0, 0, 0, 0.2) 0px 5px 15px 0px, 
      rgba(0, 0, 0, 0.45) 0px 5px 15px 0px, 
      #463e41 0px 0px 2px 0px, 
      inset rgba(0, 0, 0, 0.25) 0px 5px 15px 0px, 
      0px 0px 15px #ffff0085;

    .shimmer {
      display: block;
      background: linear-gradient(30deg, transparent 40%, $found 50%, transparent 60%);
      background-size: 500%;
      background-position-x: 100%;
      animation: shimmer 10s infinite linear, delay-animation 2000ms linear infinite;
    }
  }

  &.joker, &.hidden {
    border-radius: 34px;
    .shimmer {
      border-radius: 34px;
    }
  }
  
  &.hidden {
    background: white;
    .shimmer {
      display: none;
    }
  }

  &.hideLabel {
    .specialRune {
      display: none;
    }
  }

  img.hideTriesRune {
    filter: grayscale(1);
    opacity: 0.1;
    position: relative;
    left: -3px;
    top: 2px;
  }

  @keyframes shimmer {
    2%, 100% {
      background-position-x: 0%
    }
  }

 @keyframes delay-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    50.01% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  &.glow_heimdall {
    // border: 1px solid $heimdall;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 5px 15px 0px, 0px 0px 12px saturate(lighten($heimdall, 45%), 70%);
  }
  &.glow_heimdall_pulse {
    animation: glow_heimdall_pulse (2s) infinite;
  }
  &.glow_tyr {
    // border: 1px solid $tyr;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 5px 15px 0px, 0px 0px 12px saturate(lighten($tyr, 45%), 70%);
  }
  &.glow_tyr_pulse {
    animation: glow_tyr_pulse (2s) infinite;
  }
  &.glow_freyr {
    // border: 1px solid $freyr;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 5px 15px 0px, 0px 0px 12px saturate(lighten($freyr, 45%), 70%);
  }
  &.glow_freyr_pulse {
    animation: glow_freyr_pulse (2s) infinite;
  }
}

// Only in rune pool
.runeGrid .rune .sigil {
  display: none;
}

.runePool .rune, .currentTry .rune, .runeDemo .rune {
  .sigil {
    position: absolute;
    bottom: -1px;
    right: 17px;
    width: 27px;
    height: 16px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.7);
    font-size: 0;

    img {
      width: 16px;
      margin-top: 4px;
    }

    &.heimdall { 
      background-color: saturate(lighten($heimdall, 15%), 20%); 
      // &.hostile {
      //   animation: hostileHeimdall (2s) infinite;
      //   transition-timing-function: ease-in-out;
      // }
    }
    &.tyr { 
      background-color: saturate(lighten($tyr, 15%), 20%); 
      // &.hostile {
      //   animation: hostileTyr (2s) infinite;
      //   transition-timing-function: ease-in-out;
      // }
    }
    &.freyr { 
      background-color: saturate(lighten($freyr, 15%), 20%); 
      // &.hostile {
      //   animation: hostileFreyr (2s) infinite;
      //   transition-timing-function: ease-in-out;
      // }
    }
  }

  &.playedCombo .sigil {
    filter: saturate(0) brightness(60%);
  }

  &.notPlayedCombo .sigil {
    &.heimdall { 
      animation: hostileHeimdall (2s) infinite;
      transition-timing-function: ease-in-out;
    }
    &.tyr { 
      animation: hostileTyr (2s) infinite;
      transition-timing-function: ease-in-out;
    }
    &.freyr { 
      animation: hostileFreyr (2s) infinite;
      transition-timing-function: ease-in-out;
    }
  }

  // &.reveal:after {
  //   position: absolute;
  //   display: block;
  //   content: '+';
  //   bottom: 10px;
  //   right: 6px;
  //   width: 10px;
  //   height: 10px;
  //   font-family: 'Arial';
  //   font-size: 18px;
  // }

  &.reveal.heimdall:after { 
    color: saturate(lighten($heimdall, 15%), 20%); 
  }
  &.reveal.tyr:after { 
    color: saturate(lighten($tyr, 15%), 20%); 
  }
  &.reveal.freyr:after { 
    color: saturate(lighten($freyr, 15%), 20%); 
  }

  &.reveal.isPresent {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
  }
}

.runeSocket {
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.4) 0px 18px 36px -18px inset;
  // background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}