@import 'variables';

.scoreSelector {
  display: flex;
  width: 600px;
  gap: 20px;
}

.scoresWrapper {
  margin: 20px 0px 40px 0px;
  width: 600px;
  position: relative;

  --mask: linear-gradient(to bottom, 
      rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 70%, 
      rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;
  
  &.long {
    -webkit-mask: var(--mask); 
    mask: var(--mask);
  }
}

.scoreDailyBar {
  display: flex;
  justify-content: space-between;
  font-size: $largeFont;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0px;
  }
  .winner {
    display: flex;

    .winnerName {
      color: $found;
      margin-right: 10px;
    }
    .winnerScore {
      color: #ef3a0c;
    }
  }
}

.scoreLine {
  display: flex;
  height: 30px;
  font-size: $smallFont;
  width: 100%;

  > div {
    flex-shrink: 0;
  }

  &.header {
    font-size: $tinyFont;
    opacity: 0.5;
  }

  .scoreName {
    width: 30%;
    color: $found;
  }
  .scoreLevel {
    width: 10%;
    text-align: right;
  }
  .scoreScore {
    width: 10%;
    color: #ef3a0c;
    text-align: right;
  }
  .scoreSeed {
    width: 25%;
    color: #efd8a1;
    text-align: center;
  }
  .scoreDate {
    width: 25%;
    text-align: right;

    &.long {
      width: 50%;
    }
  }
}

.scoresList {
  height: 400px;
  overflow-y: auto;
  position: relative;

  .center {
    display: flex;
    justify-content: center;

    &:first-child {
      display: none;
    }
  }

  .noScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: $heroFont;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scoresList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scoresList {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}