@import 'variables';

.storm {
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 10;
  mix-blend-mode: difference;
  background: rgba(255,255,255, 0.1);
  opacity: 0;
  will-change: opacity; // 60FPS! :o
}

.storm[flash='1'] {
  animation: stormFlash ease-out 6s;
  animation-direction: reverse;
  animation-delay: 200ms;
}

@keyframes stormFlash {
  from { opacity: 0; } 
  92% { opacity: 0; }
  93% { opacity: 0.3; }
  94% { opacity: 0.05; }
  96% { opacity: 0.6; } 
  to { opacity: 0; }
}