// Dimensions
$actionWidth: 140px;
$callButtonWidth: 90px;
$toolRadius: 40px;
$runeCastHeight: 120px;
$poolHeight: 240px;

// Body color
$color_body: #2a2826; //#352b21;

// Runes 
$found: #F5D066;
$present: #257398;
$wrong: #3f3f3f;

// Aett
$freyr: #153833;
$freyrBG: #00834e;
$tyr: #482225;
$heimdall: #2c2c48;
$heimdallBG: #0000b5;

// Buttons & UI
$actionBarColor: #313131;
$callButtonColor: #313131;
$button: #515151;

// Fonts
$tinyFont: 14px;
$smallFont: 20px;
$largeFont: 30px;
$titleFont: 40px;
$heroFont: 60px;

/**
MHD stuff
*/

$color_body_abyss: #1b0020;
$color_button: #192c32;
$color_button_abyss: #370043;
// Combat system
$color_STR: #e7323b;
$color_MAG: #0494ab;
$color_stamina: adjust-hue($color_STR, 30); //#ce6d0a
$color_mana: adjust-hue($color_MAG, -30); //#01a972
$color_skill: #5e9273; //#01a972
$color_skill_energy: adjust-hue($color_skill, -80); //#01a972
$color_instant: #cea034;
// Elements
$element_fire: red;
$element_earth: #1c962a;
$element_water: #3042ca;
$element_light: #b4b4b4;
$element_darkness: #ea00ab;
$element_elite: gold;
// Elite monster label
$color_elite: #ff7847;
// Up/down arrows
$color_malus: #f51518;
$color_bonus: #15f515;
// Item quality
$color_itemNormal: #73746f;
$color_itemMagic: #125fa7;
$color_itemRare: #9c3db3;
$color_itemLegendary: #de5e05;
$color_legacy: #FFD700;
