@import 'variables';

.token {
  position: relative;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease-in-out;
  border-radius: 0px 6px 6px 0px;

  img {
    z-index: 1;
  }

  .description {
    position: absolute;
    display: flex;
    align-items: center;
    width: 220px;
    height: 64px;
    right: 100%;
    top: 0;
    opacity: 0;
    padding: 10px;
    border-radius: 6px 0px 0px 6px;
    padding-left: 20px;
    background-color: rgba($color: #0000002d, $alpha: 0.3);
    transition: all 400ms ease-in-out;
    z-index: 1;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba($color: #0000002d, $alpha: 0.3);
    transition: all 400ms ease-in-out;

    img{
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
      transform: scale(1.3);
      transition: all 200ms ease-in-out;
    }
    
    .description {
      opacity: 1;
      transition: all 400ms ease-in-out;
    }
  }
}

.qty {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 4px;
  padding-left: 5px;
  font-size: $smallFont;
  width: 28px;
  height: 28px;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0.5);
  border-radius: 100%;
  z-index: 2;
}