@import 'variables';

.runeCloud {
  position: fixed;
  display: flex;
  opacity: 0.2;
  mix-blend-mode: color-dodge;

  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  z-index: 2;

  .demoRuneWrapper {
    position: absolute;
    opacity: 0;
    text-shadow: 0 0 19px black;
    will-change: transform; // 60FPS! :o

    animation: runeDemo ease-in-out 5s infinite;
    // &:nth-child(odd) {
    //   animation: runeDemo ease-in-out 5s infinite;
    // }
    // &:nth-child(even) {
    //   animation: runeDemo ease-in-out 5s infinite;
    // }
  }

  .runeText {
    position: relative;
    font-family: "futhark";
    font-size: 80px;
    margin: 20px 0px;
    color: rgb(255, 255, 255);
  }
}

@keyframes runeDemo {
  0% {
    opacity: 0;
    filter: blur(20px);
  }
  50% {
    opacity: 1;
    transform: scale(3);
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    filter: blur(20px);
  }
}

@keyframes runeDemo2 {
  0% {
    opacity: 0;
    filter: blur(20px);
  }
  20% {
    filter: blur(0px);
  }
  50% {
    opacity: 1;
    transform: scale(3);
  }
  80% {
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    filter: blur(20px);
  }
}