@import 'variables';

.score {
  position: fixed;
  text-align: center;
  margin-top: 21px;
  top: 0;
  right: 0;
  width: $actionWidth;
  font-family: 'norse';
  font-size: 40px;

  .currentRune {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "futhark";
    font-size: 60px;
    margin-bottom: 15px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .5));

    img {
      width: 48px;
      margin-top: 5px;
    }
  }

  .currentRuneName {
    font-size: 20px;
    text-align: center;
  }

  .currentLevel {
    font-size: $smallFont;
  }

  .currentScore {
    margin-top: 15px;

    .title {
      font-size: $smallFont;
    }
  }

}