@import 'variables';

.introWrapper {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dummyWrapper {
  position: relative;
  // display: grid;
  // grid-template-columns: 50px 50px 50px 50px 50px 50px;
  // grid-template-rows: 100px;
  // flex-direction: row;
  // flex-wrap: wrap;
  margin: 40px 0px;
  height: 100px;
  width: 330px;

  .introDummy {
    position: absolute;
    top: 0;
  }

  // > div {
  //   position: relative;
  //   left: -14px;
  // }
}