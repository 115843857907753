@import 'variables';

.newRuneWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.runeDemoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.addEffects {
  margin-bottom: 40px;
}

.newRune {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  height: 100%;
  width: 100%;

  h1 {
    font-family: 'norse';
    margin: 0px;
    font-size: 60px;
  }

  h2 {
    font-family: 'norse';
    margin: 0px;
    font-size: 20px;
  }

  span {
    display: inline-block;
    width: 24px;
    height: 16px;
    border-radius: 10px;
    margin-right: 6px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.7);

    &.heimdall { 
      background-color: saturate(lighten($heimdall, 15%), 20%); 
    }
    &.tyr { 
      background-color: saturate(lighten($tyr, 15%), 20%); 
    }
    &.freyr { 
      background-color: saturate(lighten($freyr, 15%), 20%); 
    }
  }

  .picture {
    position: relative;
    font-family: "futhark";
    font-size: 360px;
    margin: 20px 0px;
    color: rgb(255, 255, 255);
  }

  &.heimdall:hover .picture {
    animation: glow_heimdall_pulse (2s) infinite;
  }
  &.tyr:hover .picture {
    animation: glow_tyr_pulse (2s) infinite;
  }
  &.freyr:hover .picture {
    animation: glow_freyr_pulse (2s) infinite;
  }
}