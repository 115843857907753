@import 'variables';

.volumeControl {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  cursor: pointer;
  z-index: 4;
  opacity: 0.5;
  transition: all 400ms ease-in-out;
  z-index: 6;
  
  &:hover {
    opacity: 1;
    transition: all 400ms ease-in-out;
  }

  img {
    width: 32px;
  }
}
