@import 'variables';

.runeCast {
  width: 100%;
  height: $runeCastHeight;
  margin-bottom: $poolHeight;
  background: rgba(255, 255, 255, 0.05);
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 4px;

  .currentTryWrapper {
    position: relative;
  }

  .currentTry, .currentTrySockets {
    position: absolute;
    display: flex;
  }

  .currentTry {
    z-index: 12;
  }
  .currentTrySockets {
    z-index: 0;
    // filter: blur(3px);
  }

  .energyCast {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .callButton {
    position: relative;
    left: 100%;
    top: 15px;
    height: $callButtonWidth;
    width: $callButtonWidth;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 5px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
    0px 7px 13px -3px rgba(0,0,0,0.3),
    0px -3px 0px 0px rgba(0,0,0,0.2) inset;
    border-left: 1px solid rgba(255, 255, 255, 0.447);
    border-right: 1px solid rgba(255, 255, 255, 0.447);
    transform: rotate(180deg);
    will-change: opacity, left, transform; // 60FPS! :o
    cursor: default;
    transition: all 1000ms ease-in-out;

    .energyContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .token {
        width: 64px;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }
    }

    &.armed {
      left: 20px;
      transform: rotate(0deg);
      transition: all 400ms ease-in-out;
      background-color: rgba(0, 0, 0, 0.19);
    }

    &.ready {
      cursor: pointer;
      background: $found;
      background-image: radial-gradient( $found 8.4%, rgba(252,152,15,1) 90.3% );
      transition: all 400ms ease-in-out;

      .token {
        cursor: pointer;
      }
      
      &:hover {
        filter: drop-shadow(0px 0px 6px black);
      }
    }
  }
}

.castButton, .victoryButton, .gameOverButton {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
}