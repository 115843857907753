@import 'variables';

.welcome {
  font-size: 260px;
  font-family: 'norse';
  max-width: 400px;
  overflow: visible;

  .logo {
    transition: all 2s ease-in-out;
    margin-bottom: 20px;
    filter: drop-shadow(0px 0px 10px black);
  }
  &.tyr .logo { 
    filter: drop-shadow(0px 0px 6px saturate(lighten($tyr, 45%), 70%));
    transition: all 2s ease-in-out;
  }
  &.freyr .logo { 
    filter: drop-shadow(0px 0px 6px saturate(lighten($freyr, 45%), 70%));
    transition: all 2s ease-in-out;
  }

  .appInfos {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 30px;
    top: 0;
    right: 0;
    z-index: 10;
    font-size: 20px;
  }

  .localeSelector {
    span {
      margin: 6px;
      opacity: .3;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }

  .name {
    line-height: 240px;
  }

  .baseline {
    font-size: $largeFont;
  }
}

.titleBlock {
  margin: 0px 0px 20px 0px;
  text-align: center;

  .title {
    font-size: $titleFont;
  }

  .subtitle {
    font-size: $smallFont;
  }
}

.credits {
  display: grid;
  grid-template-columns: 150px 150px 150px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 10px;

  .creditItem {
    display: flex;
    flex-direction: column;
    margin: 15px;
  }

  .title {
    font-family: 'norse';
    font-size: 20px;
    margin-bottom: 5px;
  }
}

.separator {
  margin: 50px 0px;
  display: grid;
  grid-template-columns: 20px 20px 20px;

  img {
    position: relative;
    left: -5px;
  }
}
