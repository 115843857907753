@import 'variables';

.report {
  font-family: 'norse';

  h1 {
    font-size: 60px;
    margin-bottom: 0px;
  }

  .stats {
    font-size: 30px;
    text-align: right;
    margin-top: 40px;

    div {
      display: flex;
      flex-direction: row;
    }

    .title {
      width: 40%;
      margin-right: 20px;
      opacity: .4;
    }
  }
}
