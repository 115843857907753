@import 'variables';

@font-face {
  font-family: 'futhark';
  src: url('../fonts/futha.woff2') format('woff2'),
       url('../fonts/futha.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'norse';
  src: url('../fonts/norse.woff2') format('woff2'),
       url('../fonts/norse.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Tools */
textarea:focus, input:focus, button:focus{
  outline: none;
}

* { 
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; 
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

/* Main app */
html, body, #root, button {
  font-family: "norse";
  font-size: $smallFont;
  color: white;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.cursor-pointer {
  cursor: pointer;
}

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
}

body, .appBody {
  background-color: $color_body;

  // &.freyr {
  //   background-color: $freyr;
  // }
  // &.tyr, &.odin {
  //   background-color: pink;
  // }
  // &.heimdall, &.loki {
  //   background-color: $heimdall;
  // }
}

.appBody {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  transition: background-color 200ms;
  text-align: center;
  z-index: 1;
  box-shadow: inset 0px 0px 300px 200px #0000001f;
  mix-blend-mode: soft-light;
  background-image: url("data:image/svg+xml,%3C!-- svg: first layer --%3E%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='4' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");

  img {
    height: 100%;
    opacity: 0.1;
  }

  &.freyr {
    background-color: $freyrBG;
    transition: background-color 200ms;
  }
  &.tyr, &.odin {
    background-color: $tyr;
    transition: background-color 200ms;
  }
  &.heimdall, &.loki {
    background-color: $heimdallBG;
    transition: background-color 200ms;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.columnWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: $poolHeight;
  display: flex;
  justify-content: center;
  padding-right: $actionWidth;
  padding-left: $actionWidth;
  z-index: 7;

  &.large {
    padding-right: 0;
  }
}

.appWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // max-width: 450px;
  // background-color: pink;
  height: 100%;
  overflow: hidden; /* Prevents side scrolling for overflowing pictures */
  z-index: 1;

  .level {
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    margin-top: 21px;
    width: $actionWidth;
    z-index: 1;
    font-family: 'norse';
    font-size: 30px;
    color: white;
  }

  &.freyr {
    .runeGridOverlay {
      background: linear-gradient(0deg, transparent 0%, $freyr 99%);
    }
  }
  &.tyr {
    .runeGridOverlay {
      background: linear-gradient(0deg, transparent 0%, $tyr 99%);
    }
  }
  &.heimdall {
    .runeGridOverlay {
      background: linear-gradient(0deg, transparent 0%, $heimdall 99%);
    }
  }
}

// App sections
.runeGridWrapper {
  position: relative;
  width: 100%;
  flex: auto;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-right: $actionWidth;
  padding-left: $actionWidth;
  background: #0000001c;
  overflow: hidden;

  .runeGridContainer {
    height: 100%;
    width: 100%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    z-index: 2;
    --mask: linear-gradient(to top, 
      rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 60%, 
      rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;
  
    -webkit-mask: var(--mask); 
    mask: var(--mask);
  }
}

.runeGridOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 1;
}

.runePoolWrapper {
  position: relative;
  // padding: 15px 0px 40px 0px;
  width: 100%;
}

.fogContainer {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  height: 600px;
  width: 100%;
  z-index: 3;
  pointer-events: none;
}

.fogWrapper {
  height: 700px;
  width: 100%;
  z-index: 2;
  position: absolute; 
  overflow: hidden;
  pointer-events: none;
  will-change: auto;

  .fog {
    position: absolute;
    z-index: 13;
    left: 0;
    bottom: 0;
    animation: moveSlideshow 200s linear infinite;
    transition: all 1s;
  }
  
  .smoke {
    position: absolute;
    z-index: 12;
    width: 8100px;
    height: 700px;
    bottom: 0;
    animation: moveSlideshowLong 600s linear infinite;
    // filter: saturate(0);
    transition: all 1s;
  }

  &.heimdall, &.loki {
    .fog, .smoke {
      transition: all 1s;
      filter: saturate(1) hue-rotate(60deg);
    }
  }
  &.tyr, &.odin {
    .fog, .smoke {
      transition: all 1s;
      filter: saturate(1) hue-rotate(145deg);
    }
  }
  &.freyr {
    .fog, .smoke {
      transition: all 1s;
      filter: saturate(1) hue-rotate(200deg);
    }
  }
}

.fogWrapper[flash='1'] {
  animation: flash 6s linear;
  animation-direction: reverse;
}