@import 'variables';

.characterWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  transition: all 800ms ease-in-out;

  &.dark {
    opacity: 0.7;
    filter: brightness(0.5);
    z-index: 1;
  }

  &.small {
    z-index: 6;
    transition: all 800ms ease-in-out;

    > img {
      height: 50%;
      transition: all 800ms ease-in-out;
      &.hidden { right: 100%; }
      will-change: position;
    }

    .left {
      right: max(57%, calc(100vw - 325px));
    }
    .right {
      left: max(57%, calc(100vw - 325px));
    }

    .aettName.visible { 
      bottom: -200px;
      transition: all 1200ms ease-in-out; 
    }
  }

  > img {
    position: absolute;
    bottom: 0;
    height: 100%;
    filter: drop-shadow(2px 4px 30px rgba(0, 0, 0, 0.4));
    transition: all 800ms ease-in-out;
    will-change: position;

    &.hidden { right: 100%; }
  }

  .left {
    right: max(57%, calc(100vw - 750px));
  }
  .right {
    left: max(57%, calc(100vw - 750px));
  }

  .aettName {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 60px;
    font-size: 90px;
    bottom: -300px;
    text-shadow: 2px 2px 7px black;
    transition: all 1200ms ease-in-out;
    will-change: position;
    z-index: 1;

    &.visible { 
      bottom: 0;
      transition: all 1200ms ease-in-out; 
    }
  }
}